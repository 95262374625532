@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
.menu-container {
    padding: 16px;
    border-radius: 8px;
    height: 100%;
  }
  
  /* Header Section Styling */
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .menu-icon {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  
  .menu-title {
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 43.2px !important; 

  }
  
  /* Menu Items Styling */
  .menu-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .menu-text {
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 28.8px;
    font-family: "Nunito Sans", sans-serif !important;

      }
  
  /* Border Top for all menu items except the first */
  .border-top {
    border-top: 1px solid #ddd;
  }
  
  /* Chip Styling */
  .chip-api {
    width: 78px !important;
height: 37px !important;
background-color: #418A6B !important;
font-size: 20px !important;
font-weight: 700 !important;
line-height: 27.28px !important;
color: white !important;
font-family: "Nunito Sans", sans-serif !important;
border-radius: 46px !important;

  }
  
  .chip-sdk {
    width: 78px !important;
    height: 37px !important;
    background-color: #70B038 !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 27.28px !important;
    color: white !important;
    font-family: "Nunito Sans", sans-serif !important;
    border-radius: 46px !important;

    
  }
  
  /* Responsive Layout Container */
  .responsive-layout {
    padding: 16px;
  }
  
  /* Card Specific Styling */
  .card-container {
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    height: 100%;

  }
  
  /* Individual Card Styling */
  .card-1 {
    background-color: white;
  }
  
  .card-2 {
    background-color: white;
  }
  
  .card-3 {
    background-color: white;
  }
  
  .card-4 {
    background-color: white;
  }
  .hero-heading-pricing {
    font-size: 56px !important;
    font-weight: 700 !important;
    line-height: 67px;
    text-transform: none !important;
     font-family: "Nunito Sans", sans-serif !important;
     padding-top: 90px !important;

  }
.hero-subheading-coming{
  font-size: 56px !important;
  font-weight: 700 !important;
  line-height: 67px;
  text-transform: none !important;
   font-family: "Nunito Sans", sans-serif !important;
   padding-top: 30px !important;
   color: #777777 !important;
}
  
  .hero-subheading-pricing {
    color: #555555ce;
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 28px;
    text-transform: none !important;
    font-family: "Nunito Sans", sans-serif !important;
    max-width: 600px;


  }
  .line-img-pricing{
    width: 100%;
    margin-top: 120px !important;
    margin-bottom: 50px;
  }

  /* mapping card */

  /* SdkCard.css */

.sdk-card-container {
  max-width: 100%;
  padding: 24px;
  border-radius: 15px;
 border: 1px solid #00000033;
 height: 95%;
}

.sdk-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.sdk-card-heading {
  color: black !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 43px !important;
  font-family: "Nunito Sans", sans-serif !important;
  text-decoration: none !important;
  padding-top: 30px !important;
}
.sdk-card-subheading {
  color: #555555 !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  font-family: "Nunito Sans", sans-serif !important;
  text-decoration: none !important;
  margin-top: 20px;
  margin-bottom: 20px;

  max-width: 350px;
}

.sdk-button-container {
  display: flex;
  gap: 16px; /* Space between buttons */
  margin-bottom: 30px;

}

.sdk-button-primary {
  width: 200px !important;
    height: 59px !important;
    background-color: #6E00DC !important;
    border-radius: 46px !important;
    color: white !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    margin-left: 10px !important;
    border: 1px solid #6E00DC !important;
}
.sdk-button-support{
  width: 165px !important;
    height: 47px !important;
    background-color: #6E00DC !important;
    border-radius: 46px !important;
    color: white !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border: 1px solid #6E00DC !important;
    margin-top: 40px !important;
}

.sdk-button-outline {
  width: 200px !important;
    height: 59px !important;
    background-color: transparent !important;
    border-radius: 46px !important;
    color: #6E00DC !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border: 1px solid #6E00DC !important;
}
.sdk-width{
  width: 75% !important;
  margin: 0 auto;

}
.sdk-card-link {
  margin-top: 10px;
}

.sdk-link {
  color: #6E00DC;
  text-decoration: underline;
  font-size: 22px;
  font-family: "Nunito Sans", sans-serif !important;


}

.sdk-link:hover {
  text-decoration: underline;
}

.sdk-card-container-support{
box-shadow: 0px 4px 8px 0px #00000026;
padding: 50px;
margin-top: 100px;
height: 70%;

}


@media (min-width: 1300px) and (max-width: 1550px) {
  .hero-heading-pricing {
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 45px;
    text-transform: none !important;
     font-family: "Nunito Sans", sans-serif !important;
     padding-top: 100px !important;

  }

  
  .hero-subheading-pricing {
    color: #555555bd;
    font-size: 18px !important;
    line-height: 22px;
    max-width: 500px;


  }
  .sdk-card-heading {
    color: black !important;
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
  }
  .sdk-card-subheading {
    color: #555555 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
    margin-top: 20px;
    margin-bottom: 20px;
  
    max-width: 350px;
  }


  .sdk-button-primary {
    width: 140px !important;
    height: 50px !important;
      background-color: #6E00DC !important;
      border-radius: 46px !important;
      color: white !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      margin-left: 10px !important;
      border: 1px solid #6E00DC !important;
  }

  
  .sdk-button-outline {
    width: 140px !important;
      height: 50px !important;
      background-color: transparent !important;
      border-radius: 46px !important;
      color: #6E00DC !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      border: 1px solid #6E00DC !important;
  }

  .hero-heading-pricing {
    font-size: 40px !important;
    line-height: 45px;
    text-transform: none !important;
     font-family: "Nunito Sans", sans-serif !important;
     padding-top: 90px !important;

  }

  
  .hero-subheading-pricing {
    color: #555555ce;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px;
    text-transform: none !important;
    font-family: "Nunito Sans", sans-serif !important;
    max-width: 600px;


  }

  .hero-subheading-coming{
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 40px;
   
  }
  .menu-title {
    font-size: 25px !important;
    line-height: 30px !important; 

  }
  
  .menu-text {
    font-size: 18px !important;
    line-height: 23.8px;

      }

      .chip-api {
     
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 20.28px !important;

      }
        
  .chip-sdk {
     
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 20.28px !important;
    
  }
  
}

@media (min-width: 500px) and (max-width: 1300px) {


  .hero-heading-pricing {
    font-size: 35px !important;
    font-weight: 700 !important;
    line-height: 40px;
    text-transform: none !important;
     font-family: "Nunito Sans", sans-serif !important;
     padding-top: 100px !important;

  }

  
  .hero-subheading-pricing {
    color: #555555bd;
    font-size: 15px !important;
    line-height: 22px;
    max-width: 500px;


  }
  .sdk-card-heading {
    color: black !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
  }
  .sdk-card-subheading {
    color: #555555 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
    margin-top: 20px;
    margin-bottom: 20px;
  
    max-width: 350px;
  }


  .sdk-button-primary {
    width: 140px !important;
    height: 50px !important;
      background-color: #6E00DC !important;
      border-radius: 46px !important;
      color: white !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      margin-left: 10px !important;
      border: 1px solid #6E00DC !important;
  }

  
  .sdk-button-outline {
    width: 140px !important;
      height: 50px !important;
      background-color: transparent !important;
      border-radius: 46px !important;
      color: #6E00DC !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      border: 1px solid #6E00DC !important;
  }

  .hero-heading-pricing {
    font-size: 40px !important;
    line-height: 45px;
    text-transform: none !important;
     font-family: "Nunito Sans", sans-serif !important;
     padding-top: 90px !important;

  }

  
  .hero-subheading-pricing {
    color: #555555ce;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px;
    text-transform: none !important;
    font-family: "Nunito Sans", sans-serif !important;
    max-width: 600px;


  }

  .hero-subheading-coming{
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 40px;
   
  }
  .menu-title {
    font-size: 25px !important;
    line-height: 30px !important; 

  }
  
  .menu-text {
    font-size: 18px !important;
    line-height: 23.8px;

      }

      .chip-api {
     
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 20.28px !important;

      }
        
  .chip-sdk {
     
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 20.28px !important;
    
  }

  .sdk-width{
    width: 95% !important;
    margin: 0 auto;
  
  }
  .width-heading{
    width: 95%;
    margin: 0 auto;
}
.sdk-link {
  color: #6E00DC;
  text-decoration: underline;
  font-size: 15px;
  font-family: "Nunito Sans", sans-serif !important;


}

}

@media (min-width: 300px) and (max-width: 500px) {
  .sdk-link {

    font-size: 15px;
  
  
  }
  

  .hero-heading-pricing {
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 30px;
    text-transform: none !important;
     font-family: "Nunito Sans", sans-serif !important;
     padding-top: 50px !important;

  }

  
  .hero-subheading-pricing {
    color: #555555bd;
    font-size: 13px !important;
    line-height: 18px;
    max-width: 500px;


  }
  .sdk-card-heading {
    color: black !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
  }
  .sdk-card-subheading {
    color: #555555 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
    margin-top: 20px;
    margin-bottom: 20px;
  
    max-width: 350px;
  }


  .sdk-button-primary {
    width: 140px !important;
    height: 50px !important;
      background-color: #6E00DC !important;
      border-radius: 46px !important;
      color: white !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      margin-left: 10px !important;
      border: 1px solid #6E00DC !important;
  }

  
  .sdk-button-outline {
    width: 140px !important;
      height: 50px !important;
      background-color: transparent !important;
      border-radius: 46px !important;
      color: #6E00DC !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 15px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      border: 1px solid #6E00DC !important;
  }


  
  

  .hero-subheading-coming{
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 40px;
   
  }
  .menu-title {
    font-size: 18px !important;
    line-height: 20px !important; 

  }
  
  .menu-text {
    font-size: 15px !important;
    line-height: 18px;

      }

      .chip-api {
     
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 20.28px !important;

      }
        
  .chip-sdk {
     
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 20.28px !important;
    
  }

  .sdk-width{
    width: 95% !important;
    margin: 0 auto;
  
  }
  .width-heading{
    width: 95%;
    margin: 0 auto;
}
}
