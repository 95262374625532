@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: white;
}

.gradient-section {

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: radial-gradient(
    69.38% 69.38% at 35.7% 36.82%,
    #6e00dc 0%,
    #9f5ae3 100%
  );
  width: 100%;
  box-sizing: border-box;
  height: 110px;
}


.gradient-heading {
  margin: 0;
  color: white;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  margin-left: 30px !important;
}

.gradient-button {
  background: white !important;
  width: 269px;
  height: 59px;
  padding: 16px 30px 16px 30px !important;
  border-radius: 46px !important;
  color: #7f1fdf !important;
  font-size: 22px;
  line-height: 30.01px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500 !important;
  text-transform: none !important;
  margin-right: 30px !important;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  color: black !important;
  font-family: Nunito;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 24.55px;
  text-align: center;
}
.button-nav {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 24.55px;
  text-align: center;
  text-transform: none !important;
}
.menu-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.developer-icon {
  font-size: 20px;
}

.right-button {
  width: 118px !important;
height: 59px !important;
background-color: #6E00DC !important;
border-radius: 46px !important;
color: white !important;
font-family: "Nunito Sans", sans-serif !important;
font-size: 18px !important;
font-weight: 600 !important;
}



.tooltip-content .MuiTypography-root {
  display: block;
  margin-bottom: 4px;
  text-decoration: none;
  color: black;
  padding-top: 10px;
  width: 190px;
border-radius: 0px 0px 10px 10px;
opacity: 0px;


}

.tooltip-content .MuiTypography-root:last-child {
  margin-bottom: 0;
}

/* Custom styles for tooltip arrow */
.MuiTooltip-tooltip {
  background-color: white !important; /* Ensures the tooltip background is white */
  color: black; /* Ensures the text color is black */
}

.MuiTooltip-arrow {
  color: #6e00dc !important; /* Ensures the arrow is white */
}
/* header.css */

.nav-buttons .MuiButton-root:hover {
  color: #6e00dc; /* Change color on hover */
}

.menu-box:hover {
  color: #6e00dc; /* Change color on hover */
}

.menu-box.active {
  color: #6e00dc; /* Active color for text and icon */
}

.menu-box.active .developer-icon {
  color: #6e00dc; /* Active color for the icon */
}

.tooltip-content .MuiTypography-root:hover {
  color: #6e00dc; /* Hover color for menu items in tooltip */
}
/* Add this to your header.css file */

.MuiAccordion-root {
  box-shadow: none; /* Remove default shadow */
}

.MuiAccordion-root:before {
  display: none; /* Remove default border */
}

.MuiAccordionSummary-root {
  border: none; /* Remove border from summary */
}

.MuiAccordionDetails-root {
  border: none; /* Remove border from details */
}
/* Add this to your header.css file */

.MuiAccordion-root {
  box-shadow: none; /* Remove default shadow */
  border: none; /* Remove all borders */
}

.MuiAccordionSummary-root {
  border-bottom: none; /* Remove bottom border of the summary */
}

.MuiAccordionDetails-root {
  border-top: none; /* Remove top border of the details */
}
.button-nav {
  color: inherit;
  text-transform: none;
  font-weight: normal;
  transition: color 0.3s ease;
}

.button-nav.active,
.button-nav:hover {
  color: #6E00DC !important;
}

.menu-item-active {
  color: #6E00DC !important;
}
.menu-box.active .button-nav,
.menu-box.active .developer-icon {
  color: #6E00DC !important;
}

.button-nav.active,
.button-nav:hover {
  color: #6E00DC !important;
}
.app-bar-height{
  padding: 20px;
  position: sticky;

}


.button-nav {
  color: inherit;
  text-transform: none;
  font-weight: normal;
  transition: color 0.3s ease;
}

.button-nav.active,
.button-nav:hover {
  color: #6E00DC !important;
}

.menu-item-active {
  color: #6E00DC !important;
}

.menu-box.active .button-nav,
.menu-box.active .developer-icon {
  color: #6E00DC !important;
}

.developer-icon.active {
  color: #6E00DC !important;
}

.MuiAccordion-root {
  box-shadow: none;
  border: none;
}

.MuiAccordionSummary-root {
  border-bottom: none;
}

.MuiAccordionDetails-root {
  border-top: none;
}

.right-button {
  width: 118px !important;
  height: 59px !important;
  background-color: #6E00DC !important;
  border-radius: 46px !important;
  color: white !important;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.css-1f3359j-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: white !important;
  color: #6E00DC !important;


}

.custom-accordion{
  border: 1px solid rebeccapurple;
}


@media (min-width: 1300px) and (max-width: 1550px) {
  .gradient-section .gradient-heading {
    /* More specific */
    font-size: 19px;
    line-height: 24.8px;
  } .button-nav {
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 24.55px;
    text-align: center;
    text-transform: none !important;
  }
  .gradient-button{
      width: 220px !important;
      height: 50px !important;
      padding: 16px 30px 16px 30px !important;
      border-radius: 46px !important;
      color: #7f1fdf !important;
      font-size: 22px;
      line-height: 30.01px;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 500 !important;
      text-transform: none !important;
      margin-right: 30px !important;
    
  }.developer-icon {
    font-size: 15px !important;
  }
  

  .hero-subheading {
    color: #555555ce;
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 28px;
    text-align: center;
    text-transform: none !important;
    font-family: "Nunito Sans", sans-serif !important;


  }
  
  .hero-buttons {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  
  .hero-button {
    width: 200px !important;
    height: 59px !important;
    background-color: #6E00DC !important;
    border-radius: 46px !important;
    color: white !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
  }
  
  .hero-button-contact {
    width: 200px !important;
    height: 59px !important;
    background-color: transparent !important;
    border-radius: 46px !important;
    color: #6E00DC !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    margin-left: 10px !important;
    border: 1px solid #6E00DC !important;
  }
  

  .right-button {
    width: 95px !important;
  height: 59px !important;
  background-color: #6E00DC !important;
  border-radius: 46px !important;
  color: white !important;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  }
  
}

@media (min-width: 500px) and (max-width: 1300px) {
  .gradient-section .gradient-heading {
    /* More specific */
    font-size: 15px;
    line-height: 24.8px; 
     margin-left: 0px !important;


  }
  .gradient-button{
      width: 150px;
      height: 39px;
      padding: 16px 30px 16px 30px !important;
      border-radius: 46px !important;
      color: #7f1fdf !important;
      font-size: 12px !important;
      line-height: 13px !important;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 500 !important;
      text-transform: none !important;
      margin-right: 30px !important;
      white-space: nowrap;
      margin-right: 0px  !important;
    
  }

 
  .button-nav {
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 24.55px;
    text-align: center;
    text-transform: none !important;
  }
  .developer-icon {
    font-size: 15px !important;
  }
  
}

@media (min-width: 300px) and (max-width: 500px) {
  .gradient-section .gradient-heading {
    /* More specific */
    font-size: 12px;
    line-height: 24.8px; 
     margin-left: 0px !important;


  }
  .gradient-button{
      width: 100px;
      height: 39px;
      padding: 16px 30px 16px 30px !important;
      border-radius: 46px !important;
      color: #7f1fdf !important;
      font-size: 8px !important;
      line-height: 13px !important;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 500 !important;
      text-transform: none !important;
      margin-right: 30px !important;
      white-space: nowrap;
      margin-right: 0px  !important;
    
  }

  .button-nav {
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 24.55px;
    text-align: center;
    text-transform: none !important;
  }
  .logo{
    width: 100px !important;
   }
}

.right-button {
  width: 100px !important;
height: 45px !important;
background-color: #6E00DC !important;
border-radius: 46px !important;
color: white !important;
font-family: "Nunito Sans", sans-serif !important;
font-size: 15px !important;
font-weight: 500 !important;
text-transform: capitalize !important;

}

@media (min-width: 500px) and (max-width: 700px) {

  .logo{
    width: 120px !important;
   }
}
