@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.container {
  margin-top: 100px;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 10px;
}

.table-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

@media (max-width: 1400px) {
  .table-wrapper {
    width: 70%;
  }
}

.heading {
  margin-bottom: 20px;
  text-align: start;
  color: #0A0A0A;
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 700;
  font-size: 30px;
}

.description {
  margin-bottom: 20px;
  text-align: start;
  color: #555555;
  max-width: 400px;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.875em;
}

.custom-table-cell {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  font-size: 0.875em;
  background-color: #f5f5f5;
  font-family: "Nunito Sans", sans-serif !important;
}

.custom-header-cell {
  background-color: #6E00DC;
  color: #FFFFFF;
  font-size: 1.125em;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif !important;
  text-align: left;
  padding: 10px;
}

.free-text-cell {
  color: #388E3C;
  font-weight: bold;
  font-size: 0.875em;
  font-family: "Nunito Sans", sans-serif !important;
  padding: 8px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.centered-text-cell {
  text-align: center;
  background-color: #f5f5f5;
  vertical-align: middle;
  padding: 10px;
  color: #388E3C;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 6em;
  text-shadow: 9px 9px 3px rgba(0, 128, 0, 0.3);
}

@media (max-width: 768px) {
  .centered-text-cell {
    font-size: 4em;
    font-family: "Nunito Sans", sans-serif !important;
    text-shadow: 7px 6px 3px rgba(0, 128, 0, 0.3);
  }
}

@media (max-width: 576px) {
  .centered-text-cell {
    font-size: 3em;
    font-family: "Nunito Sans", sans-serif !important;
    text-shadow: 5px 5px 3px rgba(0, 128, 0, 0.3);
  }
}

.price-note {
  margin-top: 20px;
  text-align: end;
  font-size: 20px;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif !important;
  color: #777777;
}
