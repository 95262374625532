.footer {
  background-color: #2C0157; /* Adjust to match your design */
  padding: 30px 0;
}

.footer-logo img {
  max-height: 40px; /* Adjust logo size */
}

.footer-links {
  text-align: center;
}

.footer-links .footer-link {
  color: white !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  font-family: "Nunito Sans", sans-serif !important;
  text-decoration: none !important;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
  
}

.footer-links .footer-link:hover {
  color: #6E00DC !important; /* Hover color */
}

.footer-icons {
  margin-top: 20px;
}

.footer-icons .MuiIconButton-root {
  margin: 0 5px;
}

@media (max-width: 960px) {
  .footer-links {
    text-align: center;
    margin-top: 20px;
  }

  .footer-logo {
    text-align: center;
  }
}

.social-icon {
  opacity: 0.7; /* Adjust the opacity value as needed */
  transition: opacity 0.3s ease; /* Optional: Smooth transition for hover effect */
}

.social-icon:hover {
  opacity: 1; /* Optional: Full opacity on hover */
}

.copyright-heading {
  color: rgba(255, 255, 255, 0.711) !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  font-family: "Nunito Sans", sans-serif !important;
}

.line-img {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (max-width: 1550px) {
  .footer-links .footer-link {
    color: white !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
    transition: color 0.3s ease; /* Smooth transition for hover effect */
    
  }

  .copyright-heading {
    color: rgba(255, 255, 255, 0.711) !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    font-family: "Nunito Sans", sans-serif !important;
  }
}