
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.hero-section {
    background-image: url('../assets/HomeHerobg.png');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Nunito Sans", sans-serif !important;

  }
  
  .hero-content {
    max-width: 1300px;
  }
  
  .hero-heading {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 72px;
    text-align: center;
    text-transform: none !important;
     margin-top: 13% !important;
     font-family: "Nunito Sans", sans-serif !important;

  }
  
  .hero-subheading {
    color: #555555ce;
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 28px;
    text-align: center;
    text-transform: none !important;
    font-family: "Nunito Sans", sans-serif !important;


  }
  
  .hero-buttons {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  
  .hero-button {
    width: 200px !important;
    height: 59px !important;
    background-color: #6E00DC !important;
    border-radius: 46px !important;
    color: white !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
  }
  
  .hero-button-contact {
    width: 200px !important;
    height: 59px !important;
    background-color: transparent !important;
    border-radius: 46px !important;
    color: #6E00DC !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    margin-left: 10px !important;
    border: 1px solid #6E00DC !important;
  }
  
  @media (min-width: 1000px) and (max-width: 1550px) {
    
    .hero-heading {
      font-size: 50px !important;
      font-weight: 700 !important;
      line-height: 60px;
      text-align: center;
      text-transform: none !important;
       margin-top: 8% !important;
       font-family: "Nunito Sans", sans-serif !important;
  
    }
    
    .hero-subheading {
      color: #555555ce;
      font-size: 17px !important;
      font-weight: 500 !important;
      line-height: 22px;
      text-align: center;
      text-transform: none !important;
      font-family: "Nunito Sans", sans-serif !important;
      max-width: 1000px;
      margin: 0 auto;

  
  
    }
    
    .hero-buttons {
      display: flex;
      justify-content: center;
      margin-top: 5%;
    }
    
    .hero-button {
      width: 180px !important;
      height: 50px !important;
      background-color: #6E00DC !important;
      border-radius: 46px !important;
      color: white !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 18px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
    }
    
    .hero-button-contact {
      width: 180px !important;
      height: 50px !important;
      background-color: transparent !important;
      border-radius: 46px !important;
      color: #6E00DC !important;
      font-family: "Nunito Sans", sans-serif !important;
      font-size: 18px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      margin-left: 10px !important;
      border: 1px solid #6E00DC !important;
    }
    
  
  }
  @media (min-width: 900px) and (max-width: 1300px) {
    
    .hero-heading {
      font-size: 35px !important;
      font-weight: 700 !important;
      line-height: 40px;
     
  
    }
    
    .hero-subheading {
      color: #555555ce;
      font-size: 18px !important;
      font-weight: 500 !important;
      line-height: 28px;
      text-align: center;
  

  
  
    }
    
   
    
    .hero-button {
      width: 150px !important;
      height: 45px !important;
     
      font-size: 15px !important;
      font-weight: 500 !important;
    }
    
    .hero-button-contact {
      width: 150px !important;
      height: 45px !important;
    
      font-size: 15px !important;
      font-weight: 500 !important;
   
    }
    
  
  }
  @media (min-width: 700px) and (max-width: 1300px) {
    
    .hero-heading {
      font-size: 30px !important;
      line-height: 35px;
     
  
    }
    
    .hero-subheading {
      color: #555555ce;
      font-size: 15px !important;
      font-weight: 500 !important;
      line-height: 20px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
  

  
  
    }
    
   
    
    .hero-button {
      width: 130px !important;
      height: 40px !important;
     
      font-size: 12px !important;
      font-weight: 500 !important;
    }
    
    .hero-button-contact {
      width: 130px !important;
      height: 40px !important;
     
      font-size: 12px !important;
      font-weight: 500 !important;
   
    }
    
  
  }
  @media (min-width: 560px) and (max-width: 700px) {
    
    .hero-heading {
      font-size: 25px !important;
      line-height: 35px;
      padding-left: 20px;
      padding-right: 20px;
     
  
    }
    
    .hero-subheading {
      color: #555555ce;
      font-size: 10px !important;
      font-weight: 500 !important;
      line-height: 17px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
  

  
  
    }
    
   
    
    .hero-button {
      width: 120px !important;
      height: 30px !important;
     
      font-size: 12px !important;
      font-weight: 500 !important;
    }
    
    .hero-button-contact {
      width: 120px !important;
      height: 30px !important;
     
      font-size: 12px !important;
      font-weight: 500 !important;
   
    }
    
  
  }
  @media (min-width: 300px) and (max-width: 560px) {
    
    .hero-heading {
      font-size: 15px !important;
      line-height: 20px;
      padding-left: 20px;
      padding-right: 20px;
     
  
    }
    
    .hero-subheading {
      color: #555555ce;
      font-size: 10px !important;
      font-weight: 500 !important;
      line-height: 15px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 400px;
  

  
  
    }
    
   
    
    .hero-button {
      width: 100px !important;
      height: 25px !important;
     
      font-size: 8px !important;
      font-weight: 500 !important;
    }
    
    .hero-button-contact {
      width: 100px !important;
      height: 25px !important;
     
      font-size: 8px !important;
      font-weight: 500 !important;
   
    }
    
  
  }
  