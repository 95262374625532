@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");


.hero-section-downloads {
    background-color: #F1F4F5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Nunito Sans", sans-serif !important;

  }
  .hero-heading-downloads {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 72px;
    text-align: center;
    text-transform: none !important;
     margin-top: 6% !important;
     font-family: "Nunito Sans", sans-serif !important;

  }

  .downloadPage-button {
    width: 420px !important;
    height: 56px !important;
    background-color: #FFFFFF !important;
    border-radius: 46px !important;
    color: #000000 !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    margin-top: 10% !important;
  }
  .sdk-width-down{
    
    background-color: #F1F4F5;
    padding-bottom: 30px;

  
  }


.custom-card {
    margin-top: 20px;
    border-radius: 20px !important;
    height: 100% !important;


  }
  
  .custom-card img {
    margin-bottom: 10px; 
    margin: 0 auto;
    width: 96%;
    margin-top: 10px;
  }
  
  .card-title {
    color: black !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: "Nunito Sans", sans-serif !important;
    text-decoration: none !important;
  }
  
  .card-subheading {
        color: #555555 !important;
        font-size: 22px !important;
        font-weight: 500 !important;
        line-height: 28px !important;
        font-family: "Nunito Sans", sans-serif !important;
        text-decoration: none !important;
        margin-top: 20px;
        margin-bottom: 20px;
      
        max-width: 550px;
  }

  .third-card {
    margin-bottom: 100px !important; /* Adjust the value as needed */
  }
  
@media (min-width: 1300px) and (max-width: 1550px) {
 
.hero-section-downloads {
  background-color: #F1F4F5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Nunito Sans", sans-serif !important;

}
.hero-heading-downloads {
  font-size: 45px !important;
  line-height: 50px;
  text-align: center;
  text-transform: none !important;
   margin-top: 6% !important;
   font-family: "Nunito Sans", sans-serif !important;

}

.downloadPage-button {
  width: 380px !important;
  height: 40px !important;
  
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-top: 13% !important;
}
.sdk-width-down{
  
  background-color: #F1F4F5;


}


.custom-card {
  margin-top: 20px;
  border-radius: 20px !important;
  height: 100% !important;


}

.custom-card img {
  margin-bottom: 10px; 
  margin: 0 auto;
  width: 96%;
  margin-top: 10px;
}

.card-title {
  font-size: 30px !important;
  line-height: 35px !important;
}

.card-subheading {
      color: #555555a7 !important;
      font-size: 17px !important;
      line-height: 20px !important;
      margin-top: 20px;
      margin-bottom: 20px;
    
      max-width: 550px;
}

.third-card {
  margin-bottom: 100px !important; /* Adjust the value as needed */
}

}
  
@media (min-width: 600px) and (max-width: 1300px) {
 
  .hero-section-downloads {
    background-color: #F1F4F5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Nunito Sans", sans-serif !important;
  
  }
  .hero-heading-downloads {
    font-size: 45px !important;
    line-height: 50px;
    text-align: center;
    text-transform: none !important;
     margin-top: 6% !important;
     font-family: "Nunito Sans", sans-serif !important;
  
  }
  
  .downloadPage-button {
    width: 380px !important;
    height: 40px !important;
    
    font-size: 15px !important;
    font-weight: 500 !important;
    margin-top: 13% !important;
  }
  .sdk-width-down{
    
    background-color: #F1F4F5;
  
  
  }
  
  
  .custom-card {
    margin-top: 20px;
    border-radius: 20px !important;
    height: 100% !important;
  
  
  }
  
  .custom-card img {
    margin-bottom: 10px; 
    margin: 0 auto;
    width: 96%;
    margin-top: 10px;
  }
  
  .card-title {
    font-size: 20px !important;
    line-height: 35px !important;
  }
  
  .card-subheading {
        color: #555555a7 !important;
        font-size: 15px !important;
        line-height: 20px !important;
        margin-top: 20px;
        margin-bottom: 20px;
      
        max-width: 550px;
  }
  
  .third-card {
    margin-bottom: 100px !important; /* Adjust the value as needed */
  }
  
  }
  @media (min-width: 300px) and (max-width: 600px) {
 
    .hero-section-downloads {
      background-color: #F1F4F5;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: "Nunito Sans", sans-serif !important;
    
    }
    .hero-heading-downloads {
      font-size: 29px !important;
      line-height: 50px;
      text-align: center;
      text-transform: none !important;
       margin-top: 6% !important;
       font-family: "Nunito Sans", sans-serif !important;
    
    }
    
    .downloadPage-button {
      width: 200px !important;
      height: 40px !important;
      
      font-size: 8px !important;
      font-weight: 500 !important;
      margin-top: 13% !important;
    }
    .sdk-width-down{
      
      background-color: #F1F4F5;
    
    
    }
    
    
    .custom-card {
      margin-top: 20px;
      border-radius: 20px !important;
      height: 100% !important;
    
    
    }
    
    .custom-card img {
      margin-bottom: 10px; 
      margin: 0 auto;
      width: 96%;
      margin-top: 10px;
    }
    
    .card-title {
      font-size: 20px !important;
      line-height: 35px !important;
    }
    
    .card-subheading {
          color: #555555a7 !important;
          font-size: 15px !important;
          line-height: 20px !important;
          margin-top: 20px;
          margin-bottom: 20px;
        
          max-width: 550px;
    }
    
    .third-card {
      margin-bottom: 100px !important; /* Adjust the value as needed */
    }
    
    }
    