@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");



.fixed-sidebar {
  position: sticky; 
  top: 140px;
  left: 30px;
  bottom: 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto; 
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  border-radius: 20px;
  width: calc(90% - 90px);
  margin-bottom: 50px;
}

.main-container {
  display: flex;
  align-items: flex-start;
  background-color: #F1F4F5;
  font-family: "Nunito Sans", sans-serif !important;
}


.sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content-column {
  flex: 1; 
  margin-left: calc(30% - 30px);
  margin-bottom: 100px;
}


@media (max-width: 900px) {
  .fixed-sidebar {
    position: static;
    width: auto;
    margin: 20px !important;
    max-height: unset;
  }
  
  .content-column {
    margin-left: 0 !important;
  }
  .content-box {
    margin: 40px!important;
    width: auto;
    margin: 20px !important;
  
  }
}
/* .HeaderResposive-Doc{
  font-size: 20px!important;
  } */
  @media (max-width: 900px) {
    .HeaderResposive-Doc{
      font-size: 30px!important;
      }
    }
@media (min-width: 900px) {
  .content-box {

  font-family: "Nunito Sans", sans-serif !important;
  }
}.main-container {
  background-color: #F1F4F5;
  font-family: "Nunito Sans", sans-serif !important;

}
.responsive-sidebox{
  border-radius: 20px !important;
  padding: 20px!important;
  background-color: white!important;
  /* margin-bottom: 0px!important; */
}
/* .content-box{
  position: relative;
  right: 30px;
} */
/* @media (max-width: 940px) {
  .main-container {
 display: flex;
 flex-direction: column!important;
  
  }
} */
.content-box {
  background-color: white;
  font-family: "Nunito Sans", sans-serif !important;
  border-radius: 20px;
  padding: 45px;
  width: auto;
  margin: 40px;
  margin-left: 0px;
}



.list-item {
  padding-left: 16px;
  font-weight: 700!important;
  font-size: 22px;
  background-color: white!important;
}

.list-item.selected {
  font-size: 22px;
  color: #6E00DC!important;
  font-weight: 600;
  background-color: white!important;
}
















